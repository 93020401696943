
<template>
      
          <v-col
          justify="center"
          class="mt-12 text-center"
            cols="12"
            sm="8"
            md="4"
          >
          <v-form ref="form" v-model="valid">
            <v-text-field v-for="(item, index) of items" :key="index"
              
              v-model="form_data[item.name]"
              :id="item.name"
              :label="item.title"
              :name="item.name"
              :rules="item.rules && (item.name == 'email' && emailRules || textRules) || notRules"
              :type="item.type"
              required
            />
            
            <v-btn  @click="changeUserInfo()" large rounded color="primary">Змінити</v-btn>
          </v-form>
          </v-col>
        
</template>


<script>
import axios from "axios"
export default {
  name: 'user-change-info',
  props:["user"],
  data: () => ({
    is_change_form: false,
    valid: true,
    form_data: {},
    items: [
      {title: 'Імʼя', name: 'first_name', type: 'text', rules: false},
      {title: 'Прізвище', name: 'last_name', type: 'text', rules: false},
      {title: 'Телефон', name: 'phone', type: 'phone', rules: false},
      {title: 'Колл-Центр', name: 'call_centre', type: 'phone', rules: false},
      {title: 'E-mail', name: 'email', type: 'email', rules: true},
    ],
    textRules: [
      v => !!v || "Це поле обов'язкове",
    ],
    notRules: [
        v => !!v || true ,
    ],
    emailRules: [
        v => !v && true || /.+@.+\..+/.test(v) || 'E-mail не валідний',
    ],
  }),
  mounted() {
    this.form_data = JSON.parse(JSON.stringify(this.user))
  },
  watch:{
    'form_data.phone'(val){
      //console.log(val)
      if(val == '3') this.form_data.phone = '+3'
      else if(val == '8') this.form_data.phone = '+38'
      else if(val == '0') this.form_data.phone = '+380'
    },
    'form_data.call_centre'(val){
      //console.log(val)
      if(val == '3') this.form_data.call_centre = '+3'
      else if(val == '8') this.form_data.call_centre = '+38'
      else if(val == '0') this.form_data.call_centre = '+380'
    },
  },
  methods: {
    validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
    },
    async changeUserInfo(){
			if(this.$refs.form.validate()){
        try {
          const { data } = await axios({
            url: '/api/user/change_user/',
            method: 'PUT', 
            data: {
                first_name: this.form_data.first_name,
                last_name: this.form_data.last_name,
                email: this.form_data.email,
                profile: {
                    phone: this.form_data.phone,
                    call_centre: this.form_data.call_centre
                }
            }
          })
          if(data){
            this.$router.app.$snack.show("Інформацію змінено!");
            this.$store.dispatch('auth/fetchUser')
          }else{
            this.$router.app.$snack.show("Помилка!", 'deep-orange')
          }
        } catch (err) {
          this.$router.app.$sheet.show("Помилка!", err);
          
        }
      }
		},

    
  }
};

</script>

<style lang="css">

</style>