
<template>
    <div>
      <v-container
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12 mt-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Регистрация</v-toolbar-title>
                <v-spacer />
               
              </v-toolbar>
              <v-card-text>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                  <v-text-field
                    @keyup.enter="register()"
                    v-model="username"
                    label="Имя пользователя"
                    name="username"
                    :counter="20"
                    :rules="usernameRules"
                    prepend-icon="person"
                    type="text"
                    required
                  />
                  <v-text-field
                    @keyup.enter="register()"
                    v-model="email"
                    id="email"
                    name="email"
                    :rules="emailRules"
                    label="E-mail"
                    prepend-icon="email"
                    type="email"
                    required
                  ></v-text-field>
                  <v-text-field
                    @keyup.enter="register()"
                    v-model="password"
                    id="password"
                    label="Пароль"
                    name="password"
                    :rules="passwordRules"
                    prepend-icon="lock"
                    type="password"
                    required
                  />
                  <v-text-field
                    @keyup.enter="register()"
                    v-model="password_confirm"
                    id="password_confirm"
                    label="Подтверждение пароля"
                    name="password_confirm"
                    :rules="password_confirmRules"
                    prepend-icon="lock"
                    type="password"
                    required
                  />
                  <v-text-field
                    @keyup.enter="register()"
                    v-model="reg_code"
                    label="Код регистрации"
                    name="reg_code"
                    :rules="reg_codeRules"
                    prepend-icon="work"
                    type="text"
                    required
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text small color="grey" :to="{name: 'login'}">Вхід</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="register()">Регистрация</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>


<script>
import axios from "axios"
export default {
  name: 'register',
  data: () => ({
    sheet: false,
    valid: true,
    username: '',
    email: '',
    password: '',
    password_confirm: '',
    reg_code: '',
    usernameRules: [
      v => !!v || 'Имя должно быть заполнено',
      v => (v && v.length <= 20) || 'Имя не должно быть больше 20-ти символов',
      
    ],
    reg_codeRules: [
      v => !!v || 'Код Регистрации должен быть заполнен',
      
    ],
    emailRules: [
      v => !!v || 'E-mail должен быть заполнен',
      v => /.+@.+\..+/.test(v) || 'E-mail не валидный',
    ],
    passwordRules: [
      v => !!v || 'Пароль должен быть заполнен',
    ],
    
  }),
  computed: {
      password_confirmRules () {
        const rules = []
        rules.push(v => !!v || 'Пароль должен быть заполнен');
        if (this.password) {
          rules.push(v => (v && v === this.password) || 'Пароли должны совпадать');
        }
        return rules
      },
    },
  mounted() {
    //this.form.login = this.$route.query.email; // после регистрации
  },
  
  methods: {
    validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
          //console.log(this.snackbar);
        }
    },
    register() {
      
      if(this.$refs.form.validate()){
        let fd = new FormData();
        fd.append("username", this.username);
        fd.append("email", this.email);
        fd.append("password", this.password);
        fd.append("reg_code", this.reg_code);
        fd.append("first_name", '');
        fd.append("last_name", '');
        axios({
          method: "post",
          url: "/api_user/register/",
          data: fd,
        })
          .then(() => {
            //console.log(response);
            this.$router.app.$sheet.show("Регистрация успешна!");
            this.$router.push({name: "login"})
              
          })
          .catch(() => {
            //reject(err)
            //console.log(err);
             this.$router.app.$sheet.show("Ошибка при регистрации!");
          });
      }
    }
  }
};

</script>

<style lang="css">

</style>