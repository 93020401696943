
<template>
      
          <v-col
          justify="center"
          class="mt-12 text-center"
            cols="12"
            sm="8"
            md="4"
          >
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="old_password"
              id="old_password"
              label="Старий Пароль"
              name="old_password"
              :rules="passwordRules"
              type="password"
              required
            />
            <v-text-field
              v-model="password"
              id="password"
              label="Новий Пароль"
              name="new_password"
              :rules="passwordRules"
              type="password"
              required
            />
            <v-text-field
              @keyup.enter="auth()"
              v-model="password_confirm"
              id="password_confirm"
              label="Підтвердження Паролю"
              name="password_confirm"
              :rules="passwordRules"
              type="password"
              required
            />
            <v-btn :disabled="!is_change_form" @click="changePassword()" large rounded color="primary">Змінити</v-btn>
          </v-form>
          </v-col>
        
</template>


<script>
import axios from "axios"
export default {
  name: 'user-change-password',
  props:["user"],
  data: () => ({
    is_change_form: false,
    valid: true,
    old_password: '',
    password: '',
    password_confirm: '',
    passwordRules: [
      v => !!v || "Обов'язкове поле",
    ],
  }),
  mounted() {
    //
  },
  watch:{
    password(){
      this.is_change_form = (this.password && this.password_confirm) && this.password === this.password_confirm
    },
    password_confirm(){
      this.is_change_form = (this.password && this.password_confirm) && this.password === this.password_confirm
    }
  },
  methods: {
    validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
    },
    async changePassword(){
			if(this.$refs.form.validate()){
        try {
          const { data } = await axios({
            url: '/api/user/change_password/',
            method: 'PUT', 
            data: {old_password: this.old_password, new_password: this.password}
          })
          if(data){
            this.$router.app.$snack.show("Пароль змінено!");
            this.$router.push({name: 'logout'})
          }else{
            this.$router.app.$snack.show("Помилка!", 'deep-orange')
          }
          
        } catch (err) {
          this.$router.app.$sheet.show("Помилка!", err);
          
        }
      }
		},

    
  }
};

</script>

<style lang="css">

</style>