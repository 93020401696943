
<template>
      <v-container
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12 mt-12" >
              <v-toolbar
                color="secondary"
                dark
                flat
              >
                <v-toolbar-title>Авторизація</v-toolbar-title>
                <v-spacer />
               
              </v-toolbar>
              <v-card-text>
                <v-form 
                
                ref="form"
                v-model="valid"
                lazy-validation>
                  <v-text-field
                    @keyup.enter="auth()"
                    ref="login"
                    v-model="login"
                    label="Ім'я користувача"
                    name="login"
                    :counter="20"
                    :rules="loginRules"
                    prepend-icon="person"
                    type="text"
                    required
                  />

                  <v-text-field
                    @keyup.enter="auth()"
                    v-model="password"
                    id="password"
                    label="Пароль"
                    name="password"
                    :rules="passwordRules"
                    prepend-icon="lock"
                    type="password"
                    required
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn class="d-none" text small color="grey">Реєстрація</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="auth()">Увійти</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
</template>


<script>
import axios from "axios"
//simport Cookies from 'js-cookie'
export default {
  name: 'login',
  data: () => ({
    sheet: false,
    valid: true,
    login: '',
    loginRules: [
      v => !!v || "Обов'язкове поле",
      v => (v && v.length <= 20) || "Ім'я не повинно бути більше 20-ти символів",
      
    ],
    password: '',
    passwordRules: [
      v => !!v || "Обов'язкове поле",
    ],
  }),
  mounted() {
    this.$nextTick(function () {
      this.$refs.login.focus()
    }) 
  },
  
  methods: {
    validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
          //console.log(this.snackbar);
        }
    },
    async auth() {
      if(this.$refs.form.validate()){

        await axios({
          method: "post",
          headers: { "Content-type": "application/json; charset=UTF-8" },
          //url: "/api/user/token-auth/",
          url: "/api/user/token/",
          //data: fd,
          data: {"username": this.login, "password": this.password}
        })
          .then(response => {
            /*this.$store.dispatch('auth/saveToken', {
              token: response.data.token, 
            }*/
            if(response){
              this.$store.dispatch('auth/saveToken', {
                token_access: response.data.access,
                token_refresh: response.data.refresh, 
              }
              )
              const next = this.$route.query.next || {name: "home"} 
              this.$router.push(next)
            }else{
              this.$router.app.$sheet.show("Помилка!", "Невірний логін або пароль");
            }
          })
          .catch(err => {
              console.log("error auth", err);
             this.$router.app.$sheet.show("Помилка!", err);
          })
      }
    }
  }
};

</script>

<style lang="css">

</style>