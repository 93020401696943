<template>
<div></div>
</template>

<script>
export default {
  name: 'logout',

  async mounted () {
    //console.log("Logout created")
    
    await this.$store.dispatch('auth/logout')

    await this.$store.dispatch('filters/deleteFilters')
    await this.$store.dispatch('job_types/deleteJobTypes')
    await this.$store.dispatch('users/deleteUsers')
    await this.$store.commit('tabs_abonents/deactivateConponent')
    this.$router.push('login')
  }
}
</script>