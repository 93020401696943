<template>
    <div class="admin">
        <v-navigation-drawer
        class=""
        v-model="drawer"
        :clipped="$vuetify.breakpoint.mdAndUp"
        
        app
        left
        >
        <template v-slot:prepend>
            <v-list-item class="mt-5" two-line>
                <v-list-item-avatar>
                    <img v-if="user.avatar" :src="$router.app.devUrl + '/media/' + user.avatar" alt="avatar">
                    <v-icon v-else @click="is_component = ''" color="primary" large>mdi-account-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ user.username }}</v-list-item-title>
                    <v-list-item-subtitle>{{ users_type.find(x => x.id == user.user_type).name }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

        </template>

        <v-divider></v-divider>
            <v-list shaped dense>

                <v-subheader>Налаштування</v-subheader>
                
                <v-list-item-group  v-model="item" color="primary">
                    <v-list-item
                    :id="item.component"
                    v-for="(item, i) in items"
                    :key="i"
                    @click="is_component = item.component"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                
                
            </v-list>
        </v-navigation-drawer>
        
        <v-container fluid class="mt-0">
            <v-row align="center" justify="center">
                <transition name="page" mode="out-in">    
                    <component :user="user" :is="is_component"></component>
                </transition>
            </v-row>
        </v-container>
    
        <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
            bottom
            color="primary "
            dark
            fab
            fixed
            left
            @click="drawer = !drawer"
            >
            <v-app-bar-nav-icon />
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import "@/components/User/"


export default {
    name: 'user-settings-page',
    data: () => ({
        is_component: "",  
        drawer: null,
        item: '',
        menu: '',
        items: [
            { title: 'Інформація', icon: 'mdi-account', component: 'ChangeInfo' },
            { title: 'Аватар', icon: 'mdi-emoticon-happy-outline', component: 'ChangeAvatar' },
            { title: 'Пароль', icon: 'mdi-lock', component: 'ChangePassword' },
        ],
    }),
    components: {
        //
    },
    computed: {
        ...mapGetters({
        user: "auth/user",
        users_type: "users/users_type",
        }),
    },
    mounted() {
        this.$store.dispatch('auth/fetchUser');
    },
}
</script>