
<template>
      
          <v-col
          justify="center"
          class="mt-12 text-center"
            cols="12"
            sm="8"
            md="4"
          >
          <v-form>
          <v-file-input
            id="myFile"
            accept="image/png, image/jpeg, image/bmp"
            chips
            label="Змінити Аватар"
            
            @change="is_change_form = true"
          ></v-file-input>
            <v-btn :disabled="!is_change_form" @click="uploadFile()" large rounded color="primary">Зберегти</v-btn>
            </v-form>
          </v-col>
        
</template>


<script>
import axios from "axios"
export default {
  name: 'user-change-avatar',
  props:["user"],
  data: () => ({
    is_change_form: false,
    sheet: false,
    valid: true,
    
  }),
  mounted() {
    //
  },
  
  methods: {
    async uploadFile(){
			const inputElement = document.getElementById('myFile');
      const file = inputElement.files[0];
			if (!file) return

			const body = new FormData()
			body.append('avatar', file)
			//body.append('user_id', this.user.id)
			try {
				const { data } = await axios({
          url: '/api/user/upload_avatar/',
					method: 'PUT', 
          data: body
				})
				if(data){
					this.$router.app.$snack.show("Завантажено!");
          this.$store.dispatch('auth/fetchUser')
				}else{
					//alert(data.value.message)
				}
				//console.log("data from server", data.value)
				
			} catch (err) {
				this.$router.app.$sheet.show("Помилка!", err);
				
			}
		},

    
  }
};

</script>

<style lang="css">

</style>